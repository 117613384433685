import axios from "axios";
import fetcher from "./fetcher";

// const LOCATIONS_URL = "https://638938d84eccb986e88e4394.mockapi.io/locations";

export async function getLocations() {
  try {
    const response = await fetcher.get(`/api/locations/get-locations`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const getAddForm = async (payload) => {
  try {
    // console.log(payload);
    const response = await fetcher.post(`/api/locations/created-location`, payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const getDeleteLocation = async (locId) => {
  try {
    // console.log(loc_id);
    await fetcher.delete(`/api/locations/deleted-location/${locId}`);
  } catch (error) {
    throw error.response.data;
  }
}