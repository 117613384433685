import React from 'react'
import { Link, Outlet } from 'react-router-dom'

export default function HomeLayout() {
  return (
    <div className="container-fluid">
      <nav className="nav justify-content-center">
        <Link className="nav-link" to="/jira">Home</Link>
        <Link className="nav-link" to="/jira/create">Create</Link>
        <Link className="nav-link" to="/jira/backlog">Backlog</Link>
      </nav>
      <Outlet/>
    </div>
  )
  
}
