import axios from "axios";
import fetcher from "./fetcher";

// const URL = "https://638938d84eccb986e88e4394.mockapi.io/items";


// Render the Table List:
export async function getItems() {
  try {
    const response = await fetcher({
      url: `/api/items/get-items`,
      method: "GET",
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}


// Post the item into API:
export const getFormData = async (payload) => {
  try {
    const response = await fetcher({
      url: `/api/items/created-item`,
      method: "POST",
      data: payload,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}



// Delete the item out of the API
export const getDeletedId = async (itemId) => {
  try {
    const response = await fetcher.delete(`/api/items/deleted-item/${itemId}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}




// *Get the item Id from the itemList
export const getFormItemId = async (itemId) => {
  try {
    const response = await fetcher.get(`/api/items/get-itemId/${itemId}`);
    return response.data;
  } catch (error) {
    return error.response.data
  }
}


// Put: after edit the data put into the API
export const updateFormItemById = async (itemId, payload) => {
  try {
    const response = await fetcher.put(`/api/items/put-itemIdUpdate/${itemId}`, payload)
    return response.data
  } catch (error) {
    throw error.response.data;
  }
}





