import React, { useEffect, useState } from 'react'
// import { DataGrid } from '@mui/x-data-grid';
import {getItems} from '../../../API/ItemsData';
import { Button, TextField } from '@mui/material';
import useSWR, { mutate } from 'swr';
import DialogDelete from './DialogDelete';
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import { useNavigate } from 'react-router-dom';


export default function TableList({ handleEditForm  }) {

  const [open, setOpen] = useState(false);
  const [selectDeletedItem, setselectDeletedItem] = useState("");
  const navigate = useNavigate();

  
  const { data: inventories = [], isLoading } = useSWR("inventories", getItems);
 

  // Only display 10 latest data in the table:
  const inventoriesSlice = inventories.slice(-10);


 

  const handleDeletedSelectedItem = (deleteItem) => {
    // console.log(deleteItem);
    setselectDeletedItem(deleteItem);
    setOpen(true);
  }
 

  



  // Search Item in the tableList:
  // inventories == API 
  // searchItem ==> 
 
  // Search Item
  // const handleSearchItem = (searchItem) => {
  //   console.log(searchItem);
  //   setSearchTerm(searchItem);
  // }



  
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchItem = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  


  useEffect(() => {
    mutate('inventories');
    mutate('searchTerm');
  }, [searchTerm]);


  if (isLoading) {
    return (<h1>Loading...</h1>);
  }

  

  


  return (
    // Using the Bootstrap
    <div>
      <div>
        <TextField label="Search Item"
          onChange={handleSearchItem}
          value={searchTerm}
          InputProps={{
            endAdornment: (
              <IconButton onClick={clearSearch} edge="end">
                <ClearIcon />
              </IconButton>
            )
          }}
        />
      </div>

      <i>Only display 10 latest data for table</i>

      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name of Item</th>
            <th>Quantity</th>
            <th>Alert</th>
            <th>Comment</th>
            <th></th>
          </tr>
        </thead>
      
        <tbody>
          {/* used the reverse fn to display latest on bottle to top  */}
          {inventoriesSlice.filter((item) => {
            return searchTerm.toLowerCase() === '' ? item.item_name : item.item_name.toLowerCase().includes(searchTerm.toLowerCase());
          }).reverse().map((inventory) => {
            return (
              <tr key={inventory.item_id}>
                <td>{inventory.item_id}</td>
                <td>{inventory.item_name}</td>
                <td>{inventory.quantity}</td>
                <td>{inventory.alert}</td>
                <td>{inventory.comment}</td>
                <td>
                  <Button className="me-2" variant="contained" size="small"
                    onClick={() => { handleEditForm(inventory.item_id) }}
                  >Edit</Button>
                  <Button variant="contained" color="error" size="small"
                    onClick={ ()=>handleDeletedSelectedItem(inventory) }
                  >Del</Button>
                  <Button className="ms-2" variant='contained' color="info" size='small' onClick={()=>{navigate(`/view-alert/${inventory.item_id}`)}}>View</Button>
                </td>
              </tr>
            );
          })}

        </tbody>

      </table>

      {/* <Button onClick={()=>setOpen(true)}>Delete</Button> */}
        
      {open ? <DialogDelete open={open} setOpen={setOpen} deletedItem={selectDeletedItem} setSearchTerm={setSearchTerm}  /> : false}
      
      


    </div>
  )
}
