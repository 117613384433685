import { AppBar, Button, Dialog, IconButton, List, Slide, TextField, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { getStoreForm } from '../../../API/StoresAPI';
import { mutate } from 'swr';

export default function Form({ open, setOpen }) {

  // Dialog window for transition by mui:
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  });


  const formInput = object({
    store_name: string().required("Item name must be required."),
  })


  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      store_id: "",
      store_name: "",
    },
    resolver: yupResolver(formInput),
    mode: "onTouched",
  })

  const onSubmit = async (values) => {
    // console.log(values);
    try {
      await getStoreForm(values);
      mutate("storesList");
      setOpen(false)
    } catch (error) {
    }
  }





  return (
    <div>

      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Store
            </Typography>
            
          </Toolbar>
        </AppBar>
        <List>
          {/* Form place here  */}
          <div className="ms-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-1 mb-3">
                <Controller
                  control={control}
                  name="store_name"
                  render={({ field, fieldState: { error } }) => (
                    <TextField {...field} id="outlined-basic" label="Store Name" variant="outlined" {...register("store_name")} error={!!error}
                    />
                  )}
                />
              </div>
              <Button type='submit' variant="contained" color="success">save</Button>              
            </form>

          </div>
          

        </List>
      </Dialog>



















      {/* <Dialog open={open} onClose={()=>{setOpen(false)}}>
        <DialogTitle>Add Store</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={()=>{setOpen(false)}}>No</Button>
            <Button>Add</Button>
          </DialogActions>
        </DialogContent>
      </Dialog> */}
    </div>
  )
}
