import React from 'react'
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dayjs from "dayjs";
import { getDetailItem } from '../../../API/AlertViewAPI';




export default function DetailItem() {
  
  const { itemId } = useParams();
  const {data:detailItem=[] } = useSWR("itemDetail", () => getDetailItem(itemId));


  return (
    <div className="container">
      <h5>Detail Item: {detailItem[0]?.item_name}</h5>
      {detailItem.map((ele) => {
        //Format the date to display user friendly:
        const createDate = dayjs(ele.create_date).format("DD-MMM-YYYY @ h:mm a");
        const updateDate = dayjs(ele.update_date).format("DD-MMM-YYYY @ h:mm a"); 
        const checkInDate = dayjs(ele.check_in_date).format("MM-DD-YYYY");
        const checkOutDate = dayjs(ele.check_out_date).format("MM-DD-YYYY");     

        return (
          <Container fluid="sm, md, lg" key={ele.item_id}>
            <Row>
              <Col xm={1}>ID:</Col>
              <Col>{ele.item_id}</Col>
            </Row>
            <Row>
              <Col xm={1}>Created Date:</Col>
              <Col>{createDate}</Col>
            </Row>
            <Row>
              <Col xm={1}>Updated Date:</Col>
              <Col>{updateDate}</Col>
            </Row>
            <Row>
              <Col xm={1}>Item Name:</Col>
              <Col>{ele.item_name}</Col>
            </Row>
            <Row>
              <Col xm={1}>Store Name:</Col>
              <Col>{ele.store_name}</Col>
            </Row>
            <Row>
              <Col xm={1}>Quantity:</Col>
              <Col>{ele.quantity}</Col>
            </Row>
            <Row>
              <Col xm={1}>Alert:</Col>
              <Col>{ele.alert}</Col>
            </Row>
            <Row>
              <Col xm={1}>Desc:</Col>
              <Col>{ele.item_desc}</Col>
            </Row>
            <Row>
              <Col xm={1}>Comment:</Col>
              <Col>{ele.comment}</Col>
            </Row>
            <Row>
              <Col xm={1}>Check-In Date:</Col>
              <Col>{checkInDate}</Col>
            </Row>
            <Row>
              <Col xm={1}>Check-Out Date:</Col>
              <Col>{checkOutDate}</Col>
            </Row>
            <hr />
            <h5>Location of Item:</h5>
            <Row>
              <Col xm={1}>Location Name:</Col>
              <Col>{ele.loc_name}</Col>
            </Row>
            <Row>
              <Col xm={1}>Bin Name:</Col>
              <Col>{ele.bin_name} {ele.bin_id}</Col>
            </Row>

          </Container>
        );
      })}

    </div>
  )
}
