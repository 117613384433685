import React from 'react'
import useSWR, { mutate } from 'swr'
import { getStoreIdToDelete, getStoresList } from '../../../API/StoresAPI'
import { Button } from '@mui/material';

export default function TableList() {

  const {data:storesList=[]} = useSWR("storesList", getStoresList);

  console.log(storesList);

  const handleDeletedStoreId = async (storeId) => {
    // console.log(storeId);
    try {
      await getStoreIdToDelete(storeId);
      mutate("storesList");
    } catch (error) {
      
    }
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>ID Store</th>
            <th>Store Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {storesList.map((ele) => {
            return (
              <tr key={ele.store_id}>
                <td>{ele.store_id}</td>
                <td>{ele.store_name}</td>
                <td>
                  <Button variant='contained' color='error' size='small' onClick={()=>{handleDeletedStoreId(ele.store_id)}}>Del</Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}
