import React, { useState } from 'react'
import useSWR from 'swr'
import { Button, IconButton, TextField } from '@mui/material';
import ClearIcon from "@mui/icons-material/ClearOutlined";
import dayjs from 'dayjs';
import DialogCheckOut from './DialogCheckOut';
import { useNavigate } from 'react-router-dom';
import { getCheckOutAPI } from '../../../API/CheckOutAPI';



export default function CheckOutTable() {
  const { data: checkOutData = [] } = useSWR("checkOut", getCheckOutAPI);
  // console.log(checkOutData);

  const [open, setOpen] = useState(false);
  const [checkOutId, setCheckOutId] = useState("");
  const navigate = useNavigate();
  



  const handleCheckOut = (checkOutListId) => {
    // console.log(checkOutListId);
    setCheckOutId(checkOutListId);
    setOpen(true);    
  }

  const [searchCheckOut, setSearchCheckOut] = useState("");

  const handleSearchCheckOut = (e) => {
    setSearchCheckOut(e.target.value);
  }

  const clearSearchCheckOut = () => {
    setSearchCheckOut("");
  };

  


  return (
    <div>
      <div>
        <TextField label="Search Check-out"
          onChange={handleSearchCheckOut}
          value={searchCheckOut}
          InputProps={{
            endAdornment: (
              <IconButton onClick={clearSearchCheckOut} edge="end">
                <ClearIcon />
              </IconButton>
            )
          }}
        />
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th width={600}>Item Name</th>
            <th width={10}>Quantity</th>
            <th width={150}>Check-Out Date</th>
            <th width={200}>Comment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {checkOutData.filter((item) => {
            return searchCheckOut.toLowerCase() === '' ? item.item_name.toLowerCase() : item.item_name.toLowerCase().includes(searchCheckOut.toLowerCase());
          }).map((ele) => {
            const checkOutDate = dayjs(ele.check_out_date).format("DD-MMM-YYYY");
            return (
              <tr key={ele.item_id}>
                <td>{ele.item_id}</td>
                <td>{ele.item_name}</td>
                <td>{ele.quantity}</td>
                <td>{checkOutDate}</td>
                <td>{ele.comment}</td>
                <td>
                  <Button variant='contained' color="error" size='small' onClick={() => handleCheckOut(ele.item_id)}>Check-Out</Button>
                  <Button className="ms-2" variant='contained' color="info" size='small' onClick={()=>{navigate(`/view-alert/${ele.item_id}`)}}>View</Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Dialog window here  */}
      {open ? <DialogCheckOut open={open} setOpen={setOpen} checkOutId={checkOutId}/> : false}


    </div>
  )
}
