import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { mutate } from 'swr';
import { getCheckOutSpecificId, putCheckOutQuantity } from '../../../API/CheckOutAPI';



export default function DialogCheckOut({ open, setOpen, checkOutId }) {

  // console.log("Dialog", checkOutId);
  const formInput = object({
    item_name: string().required("Item name must be required."),
    quantity: string().required("Quantity must be has a number")
  })


  const { register, handleSubmit, formState: { errors }, control, reset } = useForm({
    defaultValues: {
      item_name: "",
      quantity: "",
      comment: "",
      item_desc: "",
    },
    resolver: yupResolver(formInput),
    mode: "onTouched",
  })

  const handleCheckOutUpdate = async () => {
    const selectedItem = await getCheckOutSpecificId(checkOutId);
    reset({item_id: selectedItem[0].item_id, item_name: selectedItem[0].item_name, quantity: selectedItem[0].quantity, comment: selectedItem[0].comment, item_desc: selectedItem[0].item_desc, check_out_date: new Date()});
  }

  const onSubmit = async (values) => {  
    try {
      const {item_id, quantity} = values;
      const checkOutQty = parseInt(quantity) - parseInt(checkOut);
      // console.log(checkOutQty);
      if (checkOutQty < 0) {
        alert("The item is out of inventory");
      } else {
        values = {...values, quantity: checkOutQty};    
      }
      await putCheckOutQuantity(item_id, values);
      mutate("checkOut");
      setOpen(false)    
    } catch (error) {
      throw new Error(error.response.data)
    }
    ;
  }


  


  useEffect(()=>{
    handleCheckOutUpdate();
  },[open]);

  const [checkOut, setCheckOut] = useState(1);

  const handleCheckOut = () => {
    setCheckOut(checkOut+1);
  }


  return (
    <div>
      <Dialog open={open} hideBackdrop onClose={() => { setOpen(false) }}>
        <DialogTitle>Check-Out</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-1 mb-3">
              <Controller
                control={control}
                name="item_name"
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} id="outlined-basic" label="Name of item" disabled variant="outlined" {...register("item_name")} error={!!error}
                  />
                )}
              />
            </div>

            {/* Quantity  */}
            <div className="mb-3">
              <Controller
                control={control}
                name="quantity"
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} id="outlined-basic" label="Quantity" variant="outlined" {...register("quantity")} error={!!error} disabled />
                )}
              />
            </div>
            
            {/* Check Out  */}
            <div className="mb-3">
              <Controller
                control={control}
                name="Check_out"
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} id="outlined-basic" label="check Out" value={checkOut} disabled variant="outlined" {...register("Check_out")} error={!!error} />
                )}
              />
              <div>
                <Button className="mt-1 ms-1" variant='contained' color="success" size='small' onClick={handleCheckOut} >+</Button>
                <Button className="mt-1 ms-1" variant='contained' color="error" size='small' onClick={()=>setCheckOut(1)} >X</Button>
              </div>
            </div>

            <div className="mb-3">
              <Controller control={control} name="comment" render={({ field }) => (
                <TextField {...field} id="outlined-basic" label="Comment" variant="outlined" {...register("comment")} multiline rows={3}/>
              )}
              />
            </div>

            <div className="mb-3">
              <Controller control={control} name="item_desc" render={({ field }) => (
                <TextField {...field} id="outlined-basic" label="Description" variant="outlined" {...register("item_desc")} multiline rows={5}/>
              )}
              />
            </div>


            <DialogActions>
              <Button variant='contained' color='error' onClick={() => { setOpen(false) }}>No</Button>
              <Button type="submit" variant='contained' color="success" className="me-1" >Check-Out</Button>
            </DialogActions>

          </form>
        </DialogContent>
      </Dialog>

    </div>
  )
}
