import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import ViewAlertItem from './Components/ViewAlert/ViewAlertItem';
import BinsLayout from './Components/Bins/BinsLayout';
import LocationLayout from './Components/Locations/LocationLayout';
import StoresListLayout from './Components/StoresList/StoresListLayout';
import CheckOutLayout from './Components/CheckOut/CheckOutLayout';
import CheckInLayout from './Components/CheckIn/CheckInLayout';
import DetailItem from './Components/ViewAlert/DetailItem/DetailItem';
import Home from './Components/Home/Home';
import Layout from './Components/Layout';
import HomeLayout from './Components/Jira/HomeLayout';
import HomeJira from './Components/Jira/Home/HomeJira';
import CreateJira from './Components/Jira/CreateJira/CreateJira';
import EditedIssue from './Components/Jira/EditedIssue/EditedIssue';
import Backlog from './Components/Jira/Backlog/Backlog';





function App() {
  return (
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout/>}>    
                <Route index element={<Home/>}/>
                <Route path="view-alert" element={<ViewAlertItem/>} />                 
                <Route path="view-alert/:itemId" element={<DetailItem/>} />
                <Route path="check-in" element={<CheckInLayout/>} />
                <Route path="check-out" element={<CheckOutLayout/>} />
                <Route path="stores" element={<StoresListLayout/>} />
                <Route path="locations" element={<LocationLayout/>} />
                <Route path="bins" element={<BinsLayout/>} />
            </Route>
            <Route path="/jira" element={<HomeLayout/>}>    
                <Route index element={<HomeJira/>}/>
                <Route path="/jira/create" element={<CreateJira/>}/>
                <Route path="/jira/edit/:issueId" element={<EditedIssue/>}/>
                <Route path="/jira/backlog" element={<Backlog/>}/>
            </Route>


        </Routes>
          
      </BrowserRouter>

  );
}

export default App;