import React, { useEffect, useState } from 'react'
import { FormHelperText,  MenuItem, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { Controller, useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormData, updateFormItemById } from '../../../API/ItemsData'
import useSWR, { mutate } from 'swr'
import { getStoresList } from '../../../API/StoresAPI'
import LinearProgress from '@mui/material/LinearProgress';
import { getBinsList } from '../../../API/BinsAPI'
import { getLocations } from '../../../API/LocationsAPI'


export default function Form({ editForm, btnChange, setBtnChange }) {

  // const currentDate = dayjs(); 
  // const formattedDate = currentDate.format('YYYY-MM-DD h:mm A');
  // const {data:itemIdData} = useSWR("itemId", getFormItemId);
  // const {data:formItemId=[]} = useSWR("formItemId", getFormItemId);
  // console.log(formItemId);


  const { data: storesList = [] } = useSWR("storesList", getStoresList);
  const { data: binsList = [] } = useSWR("binsList", getBinsList);
  const { data: locationsList = [] } = useSWR("locationsList", getLocations);


  // console.log(storesList);

  const [loading, setLoading] = useState(false);


  const formInput = object({
    item_name: string().required("Item name must be required."),
    quantity: string().required("Quantity must be has a number"),
    alert: string().required("Alert must be has a number"),
    store_id: string().required("Not Empty"),
  })


  const { register, handleSubmit, formState: { errors }, control, reset, setValue, } = useForm({
    defaultValues: {
      item_id: "",
      update_date: "",
      item_name: "",
      quantity: "",
      alert: "",
      comment: "",
      store_id: "",
      bin_id: "",
      loc_id: "",
      item_desc: "",
    },
    resolver: yupResolver(formInput),
    mode: "onTouched",
  });



  // Add and Edit
  const onSubmit = async (values) => {
    // console.log(values);
    const { item_id, ...newValues } = values;
    const options = item_id ? updateFormItemById(item_id, values) : getFormData(newValues);
    // let options = ""
    // if (id) {
    //   options = updateFormItemById(id, newValues);
    //   setBtnChange(false);
    // } else {
    //   options = getFormData(newValues);
    //   // console.log(setBtnChange(!btnChange));
    //   setBtnChange(false);
    // }
    try {
      await options;
      setBtnChange(false);
      reset();
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        mutate('inventories');
      }, 2000);
    } catch (error) {

    }
  }

  const handleReset = () => {
    reset();
    setBtnChange(false);
  }

  const onError = (errors) => {
    // console.log(errors);
  }


  useEffect(() => {
    if (editForm) {
      // this is old code, don't use the setValue for the form use the reset.
      setValue('item_id', editForm[0].item_id);
      setValue('quantity', editForm[0].quantity);
      setValue('item_name', editForm[0].item_name);
      setValue('alert', editForm[0].alert);
      setValue('comment', editForm[0].comment);
      setValue('update_date', new Date());
      setValue('store_id', editForm[0].store_id);
      setValue('bin_id', editForm[0].bin_id);
      setValue('loc_id', editForm[0].loc_id);
      setValue('item_desc', editForm[0].item_desc);

      // reset({item_id: editForm.item_id, quantity: editForm.quantity, item_name: editForm.item_name, alert: editForm.alert})
      // eslint-disable-next-line

    }
  }, [editForm]);



  return (
    <div>

      <h5>Enter the item</h5>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="mb-3">
          <Controller
            control={control}
            name="item_name"
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} id="outlined-basic" label="Name of item" variant="outlined" {...register("item_name")} error={!!error}
              />
            )}
          />
          {errors.item_name && <FormHelperText error>{errors.item_name.message}</FormHelperText>}
        </div>


        {/* Select option  */}
        <div className="mb-3">
          <Controller
            control={control}
            name="store_id"
            render={({ field, fieldState: { error } }) => (
              <TextField select
                {...field}
                sx={{ minWidth: 210 }}
                id="demo-simple-select-filled"
                label="Store Name"
                variant="outlined"
                {...register("store_id")}
                error={!!error}
              >
                {/* Dynamically generate options */}
                {storesList.map((ele) => (
                  <MenuItem key={ele.store_id} value={ele.store_id}>
                    {ele.store_name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          {errors.store_id && (
            <FormHelperText error>{errors.store_id.message}</FormHelperText>
          )}
        </div>

        {/* Select option for Bin  */}
        <div className="mb-3">
          <Controller
            control={control}
            name="bin_id"
            render={({ field, fieldState: { error } }) => (

              <TextField select {...field} sx={{ minWidth: 210 }}
                id="demo-simple-select-filled" label="Bin Name" variant="outlined"  {...register("bin_id")} error={!!error} >
                {/* Dynamically generate options */}
                {binsList.map((ele) => {
                  return (
                    <MenuItem key={ele.bin_id} value={ele.bin_id}>{ele.bin_name} {ele.bin_id}</MenuItem>
                  );
                })}
              </TextField>
            )}
          />
          {errors.store_id && <FormHelperText error>{errors.store_id.message}</FormHelperText>}
        </div>

        {/* Select option for Location  */}
        <div className="mb-3">
          <Controller
            control={control}
            name="loc_id"
            render={({ field, fieldState: { error } }) => (
              <TextField select {...field} sx={{ minWidth: 210 }}
                id="demo-simple-select-filled" label="Location Name" variant="outlined"  {...register("loc_id")} error={!!error} >
                {/* Dynamically generate options */}
                {locationsList.map((ele) => {
                  return (
                    <MenuItem key={ele.loc_id} value={ele.loc_id}>{ele.loc_name}</MenuItem>
                  );
                })}
              </TextField>
            )}
          />
          {errors.loc_id && <FormHelperText error>{errors.loc_id.message}</FormHelperText>}
        </div>


        {/* Quantity  */}
        <div className="mb-3">
          <Controller
            control={control}
            name="quantity"
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} id="outlined-basic" label="Quantity" variant="outlined" {...register("quantity")} error={!!error} />
            )}
          />
          {errors.quantity && <FormHelperText error>{errors.quantity.message}</FormHelperText>}
        </div>

        {/* Alert  */}
        <div className="mb-3">
          <Controller control={control} name="alert" render={({ field, fieldState: { error } }) => (
            <TextField {...field} id="outlined-basic" label="Alert item" variant="outlined" {...register("alert")} error={!!error} />
          )}
          />
          {/* <TextField id="outlined-basic" label="Alert item" variant="outlined" {...register("alert")} error={!!errors.alert} /> */}
          {errors.alert && <FormHelperText error>{errors.alert.message}</FormHelperText>}
        </div>

        {/* Comment  */}
        <div className="mb-3">
          <Controller control={control} name="comment" render={({ field }) => (
            <TextField {...field} id="outlined-basic" label="Comment" variant="outlined"
              fullWidth
              multiline
              rows={3}
              {...register("comment")}
            />
          )}
          />
        </div>


        {/* Description  */}
        <div className="mb-3">
          <Controller control={control} name="item_desc" render={({ field }) => (
            <TextField {...field} id="outlined-basic" label="Description" variant="outlined"
              fullWidth
              multiline
              rows={5}
              {...register("item_desc")}
            />
          )}
          />
        </div>



        {/* <Button type="submit" variant="contained" color="success">Submit</Button> */}
        <Button className="me-1" type="submit" variant="contained" color={btnChange ? "primary" : "success"}>{btnChange ? "Update" : "Submit"}</Button>
        {/* {error && <p>{error}</p>} */}
        <Button type='reset' variant="contained" color="error" onClick={handleReset}>Reset</Button>
      </form>
      {loading && <LinearProgress className="mt-3" />}

    </div>
  )
}
