import React from 'react'
import useSWR, { mutate } from 'swr'
import { getDeleteLocation, getLocations } from '../../../API/LocationsAPI';
import { Button } from '@mui/material';

export default function Table() {
  const {data:locations=[]} = useSWR("locationsList", getLocations);
  // console.log(locations);

  const handleDeleteLocation = async (locId) => {
    // console.log(locId);
    try {
      await getDeleteLocation(locId);
      mutate("locationsList");
    } catch (error) {

    }

  }
  
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Loc ID</th>
            <th>Loc Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {locations.map((ele) => {
            return (
              <tr key={ele.loc_id}>
                <td>{ele.loc_id}</td>
                <td>{ele.loc_name}</td>
                <td>
                  <Button variant='contained' color="error" onClick={()=>{handleDeleteLocation(ele.loc_id)}}>Del</Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}
