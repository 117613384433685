import axios from "axios";
import BASE_URL from "./env";
import fetcher from "./fetcher";


// Get the check-in list:
export async function getCheckInAPI() {
  try {
    const response = await fetcher.get(`/api/check-in/get-checkIn`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// Get the specific id in check-in list:
export const getCheckInSpecificId = async(checkInId) => {
  try {
    const response = await fetcher.get(`/api/check-in/get-checkInId/${checkInId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// Post the check-in data into API:
export const putCheckInQuantity = async (checkInId, payload) => {
  try {
    const response = await fetcher.put(`/api/check-in/put-checkInQuantity/${checkInId}`, payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

