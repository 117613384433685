import React from 'react'
import CheckOutTable from './Pages/CheckOutTable'

export default function CheckOutLayout() {
  return (
    <div>
      <h5>Check-out Item:</h5>
      <CheckOutTable/>
    </div>
    
    
  )
}
