import React from 'react'
import { Link, Outlet } from 'react-router-dom'


export default function Layout() {
  return (
    <div className="container-fluid">
      <nav className="nav justify-content-center">
        <Link className="nav-link" to="/">Home</Link> 
        <Link className="nav-link" to="/view-alert" >View Alert</Link>
        <Link className="nav-link" to="/check-in" >Check-In</Link>
        <Link className="nav-link" to="/check-out" >Check-Out</Link>
        <Link className="nav-link" to="/stores">Stores</Link>
        <Link className="nav-link" to="/locations">Locations</Link>
        <Link className="nav-link" to="/bins">Bins</Link>
        <Link className="nav-link" to="/jira">Jira</Link>
      </nav>
      <Outlet/>
    </div>
  )
}
