import axios from "axios";
import fetcher from "./fetcher";


export async function getAlertViewList () {
  try {
    const response = await fetcher.get(`/api/alert-view/get-alertView`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getDetailItem(itemId) {
  try {
    const response = await fetcher.get(`/api/alert-view/get-alertViewId/${itemId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}


