import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, TextField } from '@mui/material';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';
import { mutate } from 'swr';
import { object, string } from 'yup';
import { getAddForm } from '../../../API/LocationsAPI';

export default function Form() {

  const formInput = object({
    loc_name: string().required("Location name must be required."),
  })


  const { register, handleSubmit, formState: { errors }, control, reset } = useForm({
    defaultValues: {
      loc_name: ""
    },
    resolver: yupResolver(formInput),
    mode: "onTouched",
  })

  const onSubmit = async (values) => {
    // console.log(values);
    try {
      await getAddForm(values);
      mutate("locationsList");
      reset();
    } catch (error) {
      
    }
  }



  return (
    <div>
      <h6>Enter Location:</h6>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-1 mb-3">
          <Controller
            control={control}
            name="loc_name"
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} id="outlined-basic" label="Location Name" variant="outlined" {...register("loc_name")} error={!!error}
              />
            )}
          />
          {errors.loc_name && <FormHelperText error>{errors.loc_name.message}</FormHelperText>}
        </div>

        <Button type='submit' variant="contained" color="success">save</Button>
      </form>

    </div>
  )
}
