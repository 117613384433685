import axios from "axios";
import BASE_URL from "./env";
import fetcher from "./fetcher";


export async function getCheckOutAPI () {
  try {
    const response = await fetcher.get(`/api/check-out/get-checkOut`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const getCheckOutSpecificId = async (checkOutId) => {
  try {
    const response = await fetcher.get(`/api/check-out/get-checkOutId/${checkOutId}`);
    return response.data; 
  } catch (error) {
    throw error.response.data;
  }
}

export const putCheckOutQuantity = async (checkOutId, payload) => {
  try {
    const response = await fetcher.put(`/api/check-out/put-checkOutQuantity/${checkOutId}`, payload);
    return response.data
  } catch (error) {
    throw error.response.data;
  }
}