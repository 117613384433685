import React from 'react'
import useSWR from 'swr'
import { useNavigate } from 'react-router-dom';
import { getAlertViewList, getDetailItem } from '../../API/AlertViewAPI';


export default function ViewAlertItem() {
  const navigate = useNavigate();
  const {data: alertViewList=[], isLoading} = useSWR(["alerView"], getAlertViewList);
  // console.log(alertViewList);

  // const {data: alertTableList=[], isLoading} = useSWR(["alertTable"], getItems);

  // const {data:storesList=[]} = useSWR("storesList", getStoresList);
  // // console.log(storesList);
  // const {data:binsList=[]} = useSWR("binsList", getBinsList);
  // console.log(binsList);

  


  // Join the two tables based on a common key (in this case, 'id')
  // const joinedData = alertTableList.map((row1) => {
  //   const matchingRow2 = storesList.find((row2) => row2.store_id === row1.store_id);
  //   const matchingRow3 = binsList.find((row3) => row3.bin_id === row1.bin_id);

  //   // Return a combined object with properties from both tables
  //   return {
  //     ...row1,
  //     ...matchingRow2,
  //     ...matchingRow3,
  //   };
  // });




  // console.log(joinedData);


  // Total of item inventory
  const totalItems = alertViewList.reduce((result, item) => {
    return result + Number(item.quantity);
  }, 0);
  // console.log(totalItems);



  if (isLoading) {
    return (<h1>Loading...</h1>);
  }





  return (
    <div>
      <h5 className="text-center mt-4">Alert View Items</h5>

      <p>Total of items: {totalItems}</p>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name of Item</th>
            <th>Store Name</th>
            <th>Bin Name</th>
            <th>Quantity</th>
            <th>Alert</th>
            <th>Status Item</th>
          </tr>
        </thead>
        <tbody>
          {alertViewList.map((ele)=>{
            return (
              <tr key={ele.item_id}>
                <td>{ele.item_id}</td>
                <td onClick={()=>{navigate(`/view-alert/${ele.item_id}`)}}>{ele.item_name}</td>
                <td>{ele.store_name}</td>
                <td>{ele.bin_name} {ele.bin_id}</td>
                <td>{ele.quantity}</td>
                <td>{ele.alert}</td>
                <td style={{ color: Number(ele.quantity) >= Number(ele.alert) ? 'green' : 'red' }}>{Number(ele.quantity) >= Number(ele.alert) ? "High":"Low"}</td>
              </tr>
            );
          })}
          
        </tbody>
      </table>
    </div>
  )
}
