import React from 'react'
import Form from './Pages/Form'
import Table from './Pages/Table'


export default function LocationLayout() {
  return (
    <div>
      <Form />
      <Table />
    </div>
  )
}
