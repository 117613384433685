import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogTitle, TextField, DialogContent } from '@mui/material';
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { mutate } from 'swr';
import { getCheckInSpecificId, putCheckInQuantity } from '../../../API/CheckInAPI';



export default function DialogCheckIn( {open, setOpen, checkInId} ) {

  

  const formInput = object({
    item_name: string().required("Item name must be required."),
    quantity: string().required("Quantity must be has a number")
  })


  const { register, handleSubmit, formState: { errors }, control, reset, setValue, values } = useForm({
    defaultValues: {
      item_id: "",
      item_name: "",
      quantity: "",
      comment: "",
      check_in_date: new Date(),
      item_desc: "",
    },
    resolver: yupResolver(formInput),
    mode: "onTouched",
  });



  // console.log(updatedItemId);
  // console.log(open);

  
  const handleCheckInUpdate = async () => {
    const selectedItem = await getCheckInSpecificId(checkInId);
    reset({item_id: selectedItem[0].item_id, item_name: selectedItem[0].item_name, quantity: selectedItem[0].quantity, comment: selectedItem[0].comment, check_in_date: new Date(), item_desc: selectedItem[0].item_desc});
  }

  useEffect(() => {
    handleCheckInUpdate();
  },[open])

   


  // Submit from the form
  const onSubmit = async (values) => {
    const { item_id, quantity } = values;
    values = {...values, quantity: parseInt(quantity) + parseInt(checkIn)}
    console.log(values);
    try {
      await putCheckInQuantity(item_id, values);
      mutate('checkIn');
      setOpen(false);
    } catch (error) {
    }
  }

  const [checkIn, setCheckIn] = useState(1);
  

  const handleCheckIn = () => {
    setCheckIn(checkIn+1);
  }
  




  return (
    <React.Fragment>
      <Dialog hideBackdrop open={open} onClose={() => setOpen(false)} >
        <DialogTitle>Check In Item</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
          // noValidate
          >
            {/* Name of item  */}
            <div className="mt-1 mb-3">
              <Controller
                control={control}
                name="item_name"
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} id="outlined-basic" label="Name of item" disabled variant="outlined" {...register("item_name")} error={!!error}
                  />
                )}
              />
            </div>

            {/* Quantity  */}
            <div className="mb-3">
              <Controller
                control={control}
                name="quantity"
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} id="outlined-basic" label="Quantity" disabled variant="outlined" {...register("quantity")} error={!!error} />
                )}
              />
            </div>
            
            {/* Check In item  */}
            <div className="mb-3">
              <Controller
                control={control}
                name="Check_in"
                render={({ field, fieldState: { error } }) => (
                  <TextField {...field} id="outlined-basic" label="Check In" value={checkIn} disabled variant="outlined" {...register("check_in")} error={!!error} size='small'  />
                )}
              />
              <div>
                <Button className="mt-2 ms-1" variant='contained' size="small" color='success' onClick={handleCheckIn} >+</Button>
                <Button className="mt-2 ms-1" variant='contained' size="small" color='error' onClick={()=>setCheckIn(1)} >X</Button>
              </div>
            </div>
            
            {/* Comment  */}
            <div className="mb-3">
              <Controller control={control} name="comment" render={({ field }) => (
                <TextField {...field} id="outlined-basic" label="Comment" variant="outlined" {...register("comment")} multiline rows={3} />
              )}
              />
            </div>

            {/* Description  */}
            <div className="mb-3">
              <Controller control={control} name="item_desc" render={({ field }) => (
                <TextField {...field} id="outlined-basic" label="Description" variant="outlined" {...register("item_desc")} multiline rows={5}/>
              )}
              />
            </div>

            <DialogActions>
              <Button variant="contained" color="error" onClick={() => setOpen(false)} >No</Button>
              <Button type="submit" className="me-1" variant="contained">Update</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
