import { Button } from '@mui/material'
import React from 'react'
import { getIssues } from '../API/IssuesData';
import useSWR from 'swr';
import './style.css';
import { useNavigate } from 'react-router-dom';


export default function HomeJira() {
  const { data: issuesData = [], isLoading } = useSWR("issues", getIssues);
  
  const navigate = useNavigate();
  // const {openWindowUpdate, setOpenWindowUpdate} = useState(false);
  // console.log(openWindowUpdate);

  if (isLoading) {
    return (<h1>Loading...</h1>);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <h5>Todo</h5>
          {issuesData.filter((item) => {
            return item.status_id === 1001;
          }).map((issue) => {
            return (
              <div key={issue.issue_id} className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">{issue.title}</h5>
                  <p className="card-text">{issue.description}</p>
                  <p className="card-textp">{issue.priority_name} {issue.day_name} {issue.loc_name}</p>
                  <Button className="mt-2" variant="contained" size="small" 
                  onClick={()=>navigate(`/jira/edit/${issue.issue_id}`)}
                  // onClick={()=>setOpenWindowUpdate(true)}
                  >Details</Button>
                </div>
              </div>
            );
          })}
        </div>


        <div className="col-md-4">
          <h5>In progress</h5>
          {issuesData.filter((item) => {
            return item.status_id === 1002;
          }).map((issue) => {
            return (
              <div key={issue.issue_id} className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">{issue.title}</h5>
                  <p className="card-text">{issue.description}</p>
                  <p className="card-text">{issue.priority_name} {issue.day_name} {issue.loc_name}</p>
                  <Button className="mt-2" variant="contained" size="small" onClick={()=>navigate(`/jira/edit/${issue.issue_id}`)}>Details</Button>
                </div>
              </div>
            );
          })}
        </div>


        <div className="col-md-4">
          <h5>Done</h5>
          {issuesData.filter((item) => {
            return item.status_id === 1003;
          }).map((issue) => {
            return (
              <div key={issue.issue_id} className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title">{issue.title}</h5>
                  <p className="card-text">{issue.description}</p>
                  <p className="card-text">{issue.priority_name} {issue.day_name} {issue.loc_name}</p>
                  <Button className="mt-2" variant="contained" size="small" 
                  onClick={()=>navigate(`/jira/edit/${issue.issue_id}`)}
                  // onClick={setOpenWindowUpdate(true)}              
                  >Details</Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
      {/* {openWindowUpdate ? <EditForm openWindowUpdate={openWindowUpdate} setOpenWindowUpdate={setOpenWindowUpdate}/> : false} */}
      


    </div>
  )
}
