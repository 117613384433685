import React from 'react'
import Table from './Pages/Table'
import Form from './Pages/Form'

export default function BinsLayout() {
  return (
    <div>
      <Form/>
      <Table />
    </div>
  )
}
