import { IconButton, TextField } from '@mui/material'
import ClearIcon from "@mui/icons-material/ClearOutlined";
import React from 'react'

export default function SearchCheckIn({searchCheckIn, handleSearchCheckIn, clearSearchCheckIn}) {
  return (
    // The search is used seperate from the check-in list 
    <div>
      <TextField label="Search Check-In"
        onChange={handleSearchCheckIn}
        value={searchCheckIn}
        InputProps={{
          endAdornment: (
            <IconButton onClick={clearSearchCheckIn} edge="end">
              <ClearIcon />
            </IconButton>
          )
        }}
      />
    </div>
  )
}
