import { Button } from '@mui/material';
import React from 'react'
import { getCreateBin } from '../../../API/BinsAPI';
import { mutate } from 'swr';

export default function Form() {

  const handleCreatedBin = async () => {
    // console.log("Create the new Bin");
    const createBin = {
        bin_name: "Bin",
    }
    
    // console.log(createBin);

    try {
      await getCreateBin(createBin);
      mutate("binsList");
    } catch (error) {
      
    }
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleCreatedBin}>Create New Bin</Button>
    </div>
  )
}
