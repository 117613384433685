import axios from "axios";
import fetcher from "./fetcher";

// Call the store List in API:
export async function getStoresList() {
  try {
    // const response = await axios.get(`${BASE_URL}/api/stores/get-stores?${queryParams}=${value}`);
    const response = await fetcher.get(`/api/stores/get-stores`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// Input store name
export const getStoreForm = async (payload) => {
  try {
    // console.log(payload);
    const response = await fetcher.post(`/api/stores/created-store`, payload);
    
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// Get the store id 
export const getStoreIdToDelete = async (storeId) => {
  try {
    // console.log(storeId);
    const response = await fetcher.delete(`/api/stores/deleted-store/${storeId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}
