import React, { useState } from 'react'
import TableList from './Pages/TableList'
import { Button } from '@mui/material'
import Form from './Pages/Form'

export default function StoresListLayout() {

  const [open, setOpen] = useState(false);


  return (
    <div>
      <Button variant='contained' size='medium' onClick={()=>setOpen(true)}>Enter Store</Button>
      {open ? <Form open={open} setOpen={setOpen}/> : false}
      
      <TableList/>
      
    </div>
  )
}

