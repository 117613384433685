import React, { useState } from 'react'
import useSWR from 'swr'
import { getItems } from '../../../API/ItemsData'
import dayjs from "dayjs";
import { Button} from '@mui/material';
import DialogCheckIn from './DialogCheckIn';
import { useNavigate } from 'react-router-dom';
import { getCheckInAPI } from '../../../API/CheckInAPI';
// import DialogContentText from '@mui/material/DialogContentText';


export default function CheckInTable( {searchCheckIn} ) {
  const [open, setOpen] = useState(false);
  // console.log(open);
  const [checkInId, setCheckInId] = useState("");
  const navigate = useNavigate();

  const { data: checkInData = [] } = useSWR("checkIn", getCheckInAPI);


  const HandleUpdateItemId = (checkInListId) => {
    setCheckInId(checkInListId)
    setOpen(true)
  }
  


  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th width={600}>Item Name</th>
            <th width={10}>Quantity</th>
            <th width={150}>Check-In Date</th>
            <th width={200}>Comment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {checkInData.filter((item) => {
            return searchCheckIn.toLowerCase() === '' ? item.item_name.toLowerCase() : item.item_name.toLowerCase().includes(searchCheckIn.toLowerCase());
          }).map((ele) => {
            const checkInDate = dayjs(ele.check_in_date).format("DD-MMM-YYYY");
            return (
              <tr key={ele.item_id}>
                <td>{ele.item_id}</td>
                <td>{ele.item_name}</td>
                <td>{ele.quantity}</td>
                <td>{checkInDate}</td>
                <td>{ele.comment}</td>
                <td>
                  <Button variant="contained" color="success" size='small' onClick={() => HandleUpdateItemId(ele.item_id)} >Check In</Button>
                  <Button className="ms-2" variant='contained' color="info" size='small' onClick={()=>{navigate(`/view-alert/${ele.item_id}`)}}>View</Button>
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>


      {/* Dialog place here  */}
      {open ?  <DialogCheckIn open={open} setOpen={setOpen} checkInId={checkInId}/> : false}
     
      

    </div>
  )
}







