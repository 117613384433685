import axios from "axios";
import BASE_URL from "./env";
import fetcher from "./fetcher";

// const BINS_URL = "https://638938d84eccb986e88e4394.mockapi.io/bins";

export async function getBinsList () {
  try {
    const response = await fetcher.get(`/api/bins/get-bins`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}


export const getCreateBin = async (payload) => {
  try {
    const response = await fetcher.post(`/api/bins/created-bin`, payload);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const getDeletedBin = async (binId) => {
  try {
    const response = await fetcher.delete(`/api/bins/deleted-bin/${binId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}


