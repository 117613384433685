import React from 'react'
import useSWR, { mutate } from 'swr'
import { getBinsList, getDeletedBin } from '../../../API/BinsAPI'
import { Button } from '@mui/material'

export default function Table() {
  const {data:binsList=[] } = useSWR("binsList", getBinsList);

  const handleDeletedBin = async (binId) => {
    try {
      await getDeletedBin(binId);
      mutate("binsList");
    } catch (error) {
      
    }
  }



  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Bin ID</th>
            <th>Bin Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {binsList.map((ele) => {
            return (
              <tr key={ele.bin_id}>
                <td>{ele.bin_id}</td>
                <td>{ele.bin_name} {ele.bin_id}</td>
                <td>
                  <Button variant="contained" color="error" size='small' onClick={()=>{handleDeletedBin(ele.bin_id)}}>Del</Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}
