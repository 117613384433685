import React, { useState } from 'react'
import CheckInTable from './Pages/CheckInTable'
import SearchCheckIn from './Pages/SearchCheckIn'


export default function CheckInLayout() {


  const [searchCheckIn, setSearchCheckIn] = useState("");

  const handleSearchCheckIn = (e) => {
    setSearchCheckIn(e.target.value);
  }

  const clearSearchCheckIn = () => {
    setSearchCheckIn("");
  }

  return (
    <div>
      <h5>Check-in Item:</h5>
      <SearchCheckIn searchCheckIn={searchCheckIn} handleSearchCheckIn={handleSearchCheckIn} clearSearchCheckIn={clearSearchCheckIn}/>
      <CheckInTable searchCheckIn={searchCheckIn} />
      
      
    </div>
  )
}
